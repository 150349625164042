<template>
    <div class="main-container">

        <Header />

        <Breadcrumb :items="items" title="M&E Project Completed" />

        <!-- Projects section start -->
        <div class="page-wrapper section-space--inner--120">
            <div class="project-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="project-item-wrapper">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="section-title-area text-center">
                                            <h2 class="section-title section-space--bottom--50">
                                                {{ data.sectionTitleMAndEProject_2 }}
                                                <br><i class="fas fa-chevron-down"></i>
                                            </h2>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6 col-12 section-space--bottom--30" v-for="project in pagination()" :key="project.id">
                                        <ProjectMAndEGridCompleted :project="project" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row section-space--top--60">
                        <div class="col">
                            <ul class="page-pagination">
                                <li>
                                    <b-button :disabled="pageNumber <= 1" @click="changePageNumber(pageNumber - 1), scrollToTop()" pill size="lg" variant="light">
                                        <i class="fa fa-angle-left"></i>
                                        Prev
                                    </b-button>
                                </li>
                                <li :class="[ pageNumber == index + 1 ? 'active' : '']" :resultCount="resultCount" v-for="(item, index) in new Array(totalPages)" :key="index">
                                    <a @click="changePageNumber(index + 1), scrollToTop()">{{ index + 1 }}</a>
                                </li>
                                <li>
                                    <b-button :disabled="pageNumber >= totalPages" @click="changePageNumber(pageNumber + 1), scrollToTop()" pill size="lg" variant="light">
                                        Next
                                        <i class="fa fa-angle-right"></i>
                                    </b-button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Projects section end -->

        <BrandCarousel />

        <Footer />

        <OffCanvasMobileMenu />

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>
    import data from '../data/project.json'
    import Header from '@/components/Header';
    import Breadcrumb from '../components/Breadcrumb'
    import ProjectMAndEGridCompleted from '../components/ProjectMAndEGridCompleted'
    import BrandCarousel from '../components/BrandCarousel'
    import Footer from '../components/Footer'
    import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu';

    export default {
        components: {
            Header,
            Breadcrumb,
            ProjectMAndEGridCompleted,
            BrandCarousel,
            Footer,
            OffCanvasMobileMenu
        },
        data() {
            return {
                data,
                resultCount: 0,
                pageNumber: Number(this.$route.query.pageNumber) || 1,
                itemsPerPage: 9,
            }
        },
        metaInfo: {
            title: 'STS CAMBODIA - M&E Project Completed',
            htmlAttrs: {
                lang: 'en',
                amp: true
            }
        },
        computed: {
            totalPages() {
                return Math.ceil(this.resultCount / this.itemsPerPage);
            },
        },
        methods: {
            pagination() {
                let datas = this.data.projectGridMAndE_2

                if (!datas || datas.length != datas.length) {
                    return
                }
                this.resultCount = datas.length
                if (this.pageNumber >= this.totalPages) {
                    this.pageNumber = this.totalPages;
                }
                var index = this.pageNumber * this.itemsPerPage - this.itemsPerPage;
                return datas.slice(index, index + this.itemsPerPage);
            },
            scrollToTop() {
                window.scrollTo(0, 0)
            },

            changePageNumber(newPageNumber) {
                this.pageNumber = newPageNumber;
                this.$router.push({ path: this.$route.path, query: {
                    pageNumber: newPageNumber
                }})
            }
        }
    }
</script>

<style>
.page-item.active .page-link {  
    background-color: #020a49 !important;  
    border-color: #020a49 !important;
    color: white !important;
}

.page-item .page-link {
    color: black !important;
}
</style>
